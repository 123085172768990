@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px 70px;
    box-shadow: 4px 4px 10px 2px rgba(16, 29, 54, 0.05);
}

.app-header .header-left {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    top: -3px;
}

.app-header a {
    text-decoration: none;
}

.app-header .header-left img {
    height: 21px;
}

.app-header .header-right .donate-button {
    width: 140px;
    color: #FFFDFF;
    background: #556DC9;
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.app-header .header-right a {
    color: #101D36;
    text-decoration: none;
    margin-right: 35px;
    font-size: 15px;
    font-weight: 600;
}

.app-header .header-right a.active {
    padding-bottom: 5px;
    border-bottom: 1px solid #101D36;
}

.app-footer {
    color: #101D36;
    font-size: 18px;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    text-align: center;
}

.app-footer .footer-divider {
    height: 1px;
    background: #101D36;
    opacity: 0.5;
    margin-bottom: 30px;
}

.app-footer .footer-description {
    padding-bottom: 30px;
    font-size: 14px;
    font-weight: 300;
    font-family: Montserrat;
}

@media (max-width: 768px) {
    .app-header {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .app-header .header-right {
        margin-top: 10px;
    }

    .app-header .header-right a:last-child {
        margin-right: 0;
    }
}
.main {
    font-family: 'Open Sans';
}

.main-container {
    position: relative;
    min-height: 70vh;
}

.main-background {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.main-container .main-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    padding-bottom: 30px;
}

.main-container .main-header .main-header-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 43px;
    margin-bottom: 3vh;
    margin-top: 10vh;
    font-family: Montserrat;
}

.main-container .main-header .main-header-description {
    width: 620px;
    font-size: 20px;
    line-height: 35px;
    font-weight: normal;
}

.main-container .search-container {
    max-width: 60%;
    margin: auto;
}

.main-container .main-selectors {
    position: relative;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    background: #FFFDFF;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0px 4px 33px rgba(16, 29, 54, 0.1);
    border-top: 5px solid #556DC9;
}

.main-container .main-selectors .selector-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding-left: 40px;
    padding-right: 40px;
}

.main-container .main-selectors .separator {
    width: 1px;
    margin-top: 18px;
    margin-bottom: 18px;
    background-color: #101D36;
}

.main-container .main-selectors .selector-container span {
    font-weight: 500;
    font-size: 13px;
    color: #C2C2C2;
    padding-top: 12px;
}

.main-container .main-selectors .selector-container span.active {
    color: #101D36;
}

.main-footer {
    padding-top: 95px;
}

.main-footer .main-footer-content {
    padding: 0 95px 95px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    text-align: left;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.main-footer .main-footer-content .main-footer-description {
    font-weight: normal;
    font-size: 20px;
    color: #101D36;
    margin-right: 60px;
}

.main-footer .main-footer-content .footer-donate-button {
    border-radius: 5px;
    padding: 15px;
    width: 200px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    background: #101D36;
    color: #FFFFFF;
}

.main-container .search-results-container {
    margin: auto;
    width: 60%;
    text-align: left;
    background: #FFFDFF;
    box-shadow: 0 4px 33px rgba(16, 29, 54, 0.1);
    border-radius: 0 0 10px 10px;
    position: relative;
    top: -10px;
    padding: 45px 55px;
}

.main-container .search-results .search-header {
    font-weight: 600;
    font-size: 18px;
    color: #101D36;
    opacity: 0.8;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    padding: 20px 0 20px 0;
}

.main-container .search-results .search-events {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-height: 70vh;
    overflow: auto;
    margin-bottom: 20px;
}

.main-container .search-results .search-events.expanded {
    background-color: rgb(230, 230, 230);
}

.main-container .search-results-container .search-events .search-events-title {
    font-weight: 600;
    font-size: 22px;
    color: #101D36;
    padding: 0 0 20px 0;
    font-family: Montserrat;
}

.main-container .search-results-container .search-events .search-events-subtitle {
    margin-bottom: 15px;
}

.main-container .search-results .search-events .event-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 20px;
}

.main-container .search-results .search-events .event-container:last-child {
    padding-bottom: 0;
}

.main-container .search-results .search-events .event-container .event-title {
    color: #101D36;
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 15px;
    font-family: Montserrat;
    background: none;
    outline: none;
    border: none;
    text-align: left;
}

.event-title svg {
    margin-left: 10px;
    width: 15px;
}

.main-container .search-results .search-events .event-container .event-text {
    font-weight: normal;
    font-size: 16px;
    color: #101D36;
    padding-bottom: 20px;
}

.main-container .search-results .search-events .event-container .event-more a {
    font-weight: bold;
    font-size: 16px;
    color: #556DC9;
}

.main-container .search-results .event-numbers .event-number {
    margin-bottom: 15px;
}

.main-container .search-results .expand-events {
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(230, 230, 230);
    margin: auto auto 30px;
    display: block;
}

.main-container .search-results .expand-events svg {
    margin-left: 10px;
}

.main-container .search-results .event-numbers .event-number .number-category {
    font-weight: 600;
    font-size: 16px;
    color: #101D36;
    margin-right: 5px;
}

.main-container .search-results .event-numbers .event-number .number-value {
    color: #ed7d2b;
    font-weight: 600;
    font-size: 16px;
}

.main-container .search-results .results-container {
    padding-bottom: 30px;
}

.main-container .search-results .results-container .google-result-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.main-container .search-results .results-container .google-result-item span {
    color: #A2A1A1;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
}

.main-container .search-results .results-container .google-result-item a {
    color: #3F66FE;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.main-container .search-results .results-container .result-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 25px;
    -webkit-align-items: stretch;
            align-items: stretch;
}

.main-container .search-results .results-container .result-item img {
    width: 15%;
    object-fit: cover;
    border-radius: 5px;
    aspect-ratio: 2;
}

.main-container .search-results .results-container .result-item-body {
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 15px;
}

.main-container .search-results .results-container .search-results-header {
    color: #101D36;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: Montserrat;
}

.main-container .search-results .results-container .result-item h3 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
}

.main-container .search-results .results-container .result-item h4 {
    display: block;
    overflow: hidden;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    max-height: 50px;
}

.main-container .search-results .results-container .result-item span {
    color: #C4C4C4;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: Montserrat;
}

.main-container .search-results .results-container .result-item a {
    color: #556DC9;
    font-weight: 700;
    font-size: 16px;
}

.main-container .search-results .results-container .pagination {
    color: #A2A1A1;
    margin-top: 10px;
    margin-bottom: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.main-container .search-results .results-container .pagination > span:first-child {
    margin-right: 15px;
}

.main-container .search-results .results-container .pagination svg {
    cursor: pointer;
}

.main-container .search-results .results-container .pagination .page-selector {
    margin-left: 5px;
    margin-right: 5px;
}

.main-container .search-results .results-container .google {
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.main-container .search-results .results-container .google svg {
    margin-left: 20px;
}

.search-type-tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.main-selectors-container {
    position: relative;
}

.main-selectors-container .reset-search {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: white;
    padding: 10px 0;
}

.search-type-tab {
    background: #FFFDFF;
    color: #C2C2C2;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 40px;
    border: none;
    outline: none;
    margin-right: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.search-type-tab.active {
    background: #556DC9;
    color: #fff;
}

.main-container .search-button {
    background: #556DC9;
    outline: none;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    padding: 25px;
    color: #FFFDFF;
}

.main-container .search-button svg {
    margin-right: 10px;
}

.main-container .main-selectors .keyword-selector {
    -webkit-flex: 1 1;
            flex: 1 1;
    border: none;
    outline: none;
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.main-container .main-selectors .keyword-selector::-webkit-input-placeholder {
    color: #C2C2C2;
}

.main-container .main-selectors .keyword-selector:-ms-input-placeholder {
    color: #C2C2C2;
}

.main-container .main-selectors .keyword-selector::placeholder {
    color: #C2C2C2;
}

.main-container .main-selectors .add-filter-btn {
    border: none;
    outline: none;
    background: none;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
}

.main-container .main-selectors .add-filter-btn span {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
    line-height: 20px;
    margin-left: 10px;
    background-color: #556DC9;
    color: #fff;
}

.main-container .main-selectors .add-filter-btn.active {
    background: #e7e6e7;
}

.main-container .search-results-container .keyword-filters {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.main-container .search-results-container .keyword-filters > * {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.main-container .search-results-container .keyword-filters > *:not(:first-child) {
    margin-left: 13px;
}

.main-container .search-results-container .active-keyword-filters {
    padding: 20px;
    background-color: #e7e6e7;
    border-radius: 5px;
    margin-bottom: 25px;
}

.main-container .search-results-container .active-keyword-filters-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 5px;
}

.main-container .search-results-container .active-keyword-filters-header h3 {
    font-weight: 500;
    font-size: 18px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.main-container .search-results-container .active-keyword-filters-header button {
    background: none;
    border: none;
    outline: none;
    color: #556DC9;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.main-container .search-results-container .active-keyword-filters .filters-row {
    margin-bottom: 10px;
}

.main-container .search-results-container .chip {
    background: #FFFDFF;
    border-radius: 50px;
    font-size: 13px;
    padding: 2px 8px;
    margin-left: 10px;
}

.main-container .search-results-container .chip > button {
    margin-left: 3px;
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.main-container .search-results-container .keyword-filters .filter-placeholder {
    color: #C2C2C2;
}

.main-container .search-results-container .keyword-filters .filter-placeholder svg {
    margin-right: 5px;
}

.main-container .search-results-container .search-results-separator {
    height: 1px;
    background: #C2C2C2;
    margin: 35px -55px;
}

@media (max-width: 768px) {
    .main-container .main-header .main-header-title {
        font-size: 32px;
        line-height: 1.2;
    }

    .main-container .main-header .main-header-description {
        width: auto;
        font-size: 18px;
        line-height: 24px;
    }

    .main-container .main-header {
        padding-left: 16px;
        padding-right: 16px;
    }

    .main-container .search-container {
        max-width: 100%;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .search-type-tab {
        padding: 10px 16px;
        margin-right: 10px;
        font-size: 12px;
    }

    .main-selectors-container .reset-search {
        font-size: 12px;
        position: static;
        float: right;
        width: 58px;
    }

    .main-container .main-selectors .selector-container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .main-container .main-selectors {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .main-container .main-selectors .separator {
        width: calc(100% - 16px);
        height: 1px;
        margin: 5px auto 0;
        background-color: #C2C2C2;
    }

    .main-container .search-button {
        font-size: 16px;
        padding: 10px;
        margin-top: 15px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
    }

    .main-container .main-selectors .keyword-selector {
        margin-top: 15px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
    }

    .main-container .main-selectors .add-filter-btn {
        margin-top: 15px;
        font-size: 16px;
    }

    .main-container .main-selectors .add-filter-btn.active {
        background: none;
    }

    .main-container .search-results-container {
        width: calc(100% - 32px);
        margin: 25px 16px auto;
        border-radius: 10px;
        padding: 16px;
    }

    .main-container .search-results-container .keyword-filters {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .main-container .search-results-container .keyword-filters > *:not(:first-child) {
        margin: 13px 0 0;
    }

    .main-container .search-results-container .search-results-separator {
        margin: 16px 0;
    }

    .main-container .search-results .results-container .search-results-header {
        font-size: 18px;
    }

    .main-container .search-results-container .search-events .search-events-title {
        font-size: 18px;
    }

    .main-container .search-results .search-events .event-container .event-title {
        font-size: 18px;
    }

    .main-container .search-results .results-container .result-item h3 {
        font-size: 18px;
    }

    .main-container .search-results .results-container .result-item img {
        width: 100%;
    }

    .main-container .search-results .results-container .result-item {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom: 40px;
    }

    .main-container .search-results .results-container .result-item-body {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
    }

    .main-container .search-results .results-container .google-result-item {
        margin-bottom: 20px;
    }

    .main-container .search-results .results-container .google-result-item span {
        margin-bottom: 5px;
    }

    .main-container .search-results .results-container .google-result-item a {
        font-size: 16px;
        word-break: break-all;
    }

    .main-footer {
        padding-top: 40px;
    }

    .main-footer .main-footer-content {
        padding: 0 30px 40px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        text-align: left;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    .main-footer .main-footer-content .main-footer-description {
        -webkit-flex: 1 1;
                flex: 1 1;
        margin-right: 32px;
        font-size: 18px;
    }

    .main-footer .main-footer-content .footer-donate-button {
        -webkit-flex: 0 1;
                flex: 0 1;
        font-size: 18px;
    }
}
.contacts-container {
    padding: 70px 250px;
    font-family: "Open Sans";
}

.contacts-container .contacts-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: left;
}

.contacts-container .contacts-header .contacts-header-title {
    font-weight: 600;
    font-size: 35px;
    color:  #101D36;
    font-family: Montserrat;
    margin-bottom: 15px;
}

.contacts-container .contacts-header .contacts-header-description {
    font-weight: normal;
    font-size: 18px;
    color:  #101D36;
    margin-top: 10px;
    margin-bottom: 15px;
}

.contacts-container .contacts-body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.contacts-container .contacts-body .contacts-form form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.contacts-container .contacts-body .contacts-form form .row {
    --bs-gutter-x: 1rem
}

.contacts-container .contacts-body .contacts-form input, .contacts-container .contacts-body .contacts-form textarea {
    width: 100%;
    padding: 16px;
    font-size: 20px;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    color: #101D36;
    margin-bottom: 15px;
}

.contacts-container .contacts-body .contacts-form textarea {
    resize: none;
    height: 225px;
}

.contacts-container .contacts-body .contacts-form button {
    background: #556DC9;
    border: none;
    color: #F6F6F6;
    border-radius: 10px;
    padding: 20px;
    font-weight: 600;
    font-size: 21px;
}

.contacts-container .contacts-body .contacts-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: left;
    margin-left: 150px;
    color: #101D36;
    font-weight: 400;
    font-size: 20px;
}

.contacts-container .contacts-body .contacts-info .contacts-phone {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.contacts-container .contacts-body .contacts-info svg {
    margin-right: 13px;
}

.contacts-container .contacts-body .contacts-info .contacts-location {
    display: -webkit-flex;
    display: flex;
}

.contacts-container .contacts-body .contacts-info .contacts-location svg {
    margin-top: 7px;
}

.contacts-container .contacts-body .contacts-info .contacts-location svg path {
    fill: #101D36;
}

.contacts-container .contacts-body .contacts-info .contacts-location span {
    width: 330px;
}

@media (max-width: 768px) {
    .contacts-container {
        padding: 24px 16px;
        font-family: "Open Sans";
    }

    .contacts-container .contacts-header .contacts-header-title {
        font-size: 24px;
    }

    .contacts-container .contacts-body {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }

    .contacts-container .contacts-body .contacts-info {
        margin-left: 0;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .contacts-container .contacts-body .contacts-form input, .contacts-container .contacts-body .contacts-form textarea {
        font-size: 18px;
    }

    .contacts-container .contacts-body .contacts-form button {
        font-size: 18px;
    }
}
.about-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-top: 70px;
    padding-left: 85px;
    padding-right: 85px;
    -webkit-align-items: center;
            align-items: center;
}

.about-container .about-image {
    -webkit-flex: 1 1;
            flex: 1 1;
    border-radius: 10px;
}

.about-container .about-body .about-header {
    font-weight: 700;
    font-size: 48px;
    color: #101D36;
    font-family: Montserrat;
    text-align: left;
}

.about-container .about-body {
    margin-right: 60px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.about-container .about-body .about-text p {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #101D36;
    text-align: left;
    margin-top: 20px;
    font-family: "Open Sans";
}

.about-container .about-body .about-text {
    padding-bottom: 30px;
}

@media (max-width: 768px) {
    .about-container {
        padding: 24px 16px 0;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .about-container .about-body {
        margin: 0;
    }

    .about-container .about-body .about-header {
        font-size: 32px;
    }

    .about-container .about-body .about-text p {
        font-size: 16px;
    }

    .about-container .about-image {
        border-radius: 0;
        margin-right: -16px;
        margin-left: -16px;
        width: calc(100% + 32px);
    }
}
html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 75px;
}

.App {
  text-align: center;
}

.app-content {
  /*padding: 40px 70px;*/
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
