.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 70px;
    box-shadow: 4px 4px 10px 2px rgba(16, 29, 54, 0.05);
}

.app-header .header-left {
    height: fit-content;
    position: relative;
    top: -3px;
}

.app-header a {
    text-decoration: none;
}

.app-header .header-left img {
    height: 21px;
}

.app-header .header-right .donate-button {
    width: 140px;
    color: #FFFDFF;
    background: #556DC9;
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.app-header .header-right a {
    color: #101D36;
    text-decoration: none;
    margin-right: 35px;
    font-size: 15px;
    font-weight: 600;
}

.app-header .header-right a.active {
    padding-bottom: 5px;
    border-bottom: 1px solid #101D36;
}

.app-footer {
    color: #101D36;
    font-size: 18px;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    text-align: center;
}

.app-footer .footer-divider {
    height: 1px;
    background: #101D36;
    opacity: 0.5;
    margin-bottom: 30px;
}

.app-footer .footer-description {
    padding-bottom: 30px;
    font-size: 14px;
    font-weight: 300;
    font-family: Montserrat;
}

@media (max-width: 768px) {
    .app-header {
        flex-direction: column;
    }

    .app-header .header-right {
        margin-top: 10px;
    }

    .app-header .header-right a:last-child {
        margin-right: 0;
    }
}