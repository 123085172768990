.about-container {
    display: flex;
    flex-direction: row;
    padding-top: 70px;
    padding-left: 85px;
    padding-right: 85px;
    align-items: center;
}

.about-container .about-image {
    flex: 1;
    border-radius: 10px;
}

.about-container .about-body .about-header {
    font-weight: 700;
    font-size: 48px;
    color: #101D36;
    font-family: Montserrat;
    text-align: left;
}

.about-container .about-body {
    margin-right: 60px;
    flex: 1;
}

.about-container .about-body .about-text p {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #101D36;
    text-align: left;
    margin-top: 20px;
    font-family: "Open Sans";
}

.about-container .about-body .about-text {
    padding-bottom: 30px;
}

@media (max-width: 768px) {
    .about-container {
        padding: 24px 16px 0;
        flex-direction: column;
    }

    .about-container .about-body {
        margin: 0;
    }

    .about-container .about-body .about-header {
        font-size: 32px;
    }

    .about-container .about-body .about-text p {
        font-size: 16px;
    }

    .about-container .about-image {
        border-radius: 0;
        margin-right: -16px;
        margin-left: -16px;
        width: calc(100% + 32px);
    }
}