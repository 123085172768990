.contacts-container {
    padding: 70px 250px;
    font-family: "Open Sans";
}

.contacts-container .contacts-header {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.contacts-container .contacts-header .contacts-header-title {
    font-weight: 600;
    font-size: 35px;
    color:  #101D36;
    font-family: Montserrat;
    margin-bottom: 15px;
}

.contacts-container .contacts-header .contacts-header-description {
    font-weight: normal;
    font-size: 18px;
    color:  #101D36;
    margin-top: 10px;
    margin-bottom: 15px;
}

.contacts-container .contacts-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contacts-container .contacts-body .contacts-form form {
    display: flex;
    flex-direction: column;
}

.contacts-container .contacts-body .contacts-form form .row {
    --bs-gutter-x: 1rem
}

.contacts-container .contacts-body .contacts-form input, .contacts-container .contacts-body .contacts-form textarea {
    width: 100%;
    padding: 16px;
    font-size: 20px;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    color: #101D36;
    margin-bottom: 15px;
}

.contacts-container .contacts-body .contacts-form textarea {
    resize: none;
    height: 225px;
}

.contacts-container .contacts-body .contacts-form button {
    background: #556DC9;
    border: none;
    color: #F6F6F6;
    border-radius: 10px;
    padding: 20px;
    font-weight: 600;
    font-size: 21px;
}

.contacts-container .contacts-body .contacts-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 150px;
    color: #101D36;
    font-weight: 400;
    font-size: 20px;
}

.contacts-container .contacts-body .contacts-info .contacts-phone {
    display: flex;
    align-items: center;
}

.contacts-container .contacts-body .contacts-info svg {
    margin-right: 13px;
}

.contacts-container .contacts-body .contacts-info .contacts-location {
    display: flex;
}

.contacts-container .contacts-body .contacts-info .contacts-location svg {
    margin-top: 7px;
}

.contacts-container .contacts-body .contacts-info .contacts-location svg path {
    fill: #101D36;
}

.contacts-container .contacts-body .contacts-info .contacts-location span {
    width: 330px;
}

@media (max-width: 768px) {
    .contacts-container {
        padding: 24px 16px;
        font-family: "Open Sans";
    }

    .contacts-container .contacts-header .contacts-header-title {
        font-size: 24px;
    }

    .contacts-container .contacts-body {
        flex-direction: column-reverse;
    }

    .contacts-container .contacts-body .contacts-info {
        margin-left: 0;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .contacts-container .contacts-body .contacts-form input, .contacts-container .contacts-body .contacts-form textarea {
        font-size: 18px;
    }

    .contacts-container .contacts-body .contacts-form button {
        font-size: 18px;
    }
}