.main {
    font-family: 'Open Sans';
}

.main-container {
    position: relative;
    min-height: 70vh;
}

.main-background {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.main-container .main-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding-bottom: 30px;
}

.main-container .main-header .main-header-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 43px;
    margin-bottom: 3vh;
    margin-top: 10vh;
    font-family: Montserrat;
}

.main-container .main-header .main-header-description {
    width: 620px;
    font-size: 20px;
    line-height: 35px;
    font-weight: normal;
}

.main-container .search-container {
    max-width: 60%;
    margin: auto;
}

.main-container .main-selectors {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #FFFDFF;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0px 4px 33px rgba(16, 29, 54, 0.1);
    border-top: 5px solid #556DC9;
}

.main-container .main-selectors .selector-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
}

.main-container .main-selectors .separator {
    width: 1px;
    margin-top: 18px;
    margin-bottom: 18px;
    background-color: #101D36;
}

.main-container .main-selectors .selector-container span {
    font-weight: 500;
    font-size: 13px;
    color: #C2C2C2;
    padding-top: 12px;
}

.main-container .main-selectors .selector-container span.active {
    color: #101D36;
}

.main-footer {
    padding-top: 95px;
}

.main-footer .main-footer-content {
    padding: 0 95px 95px;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: center;
}

.main-footer .main-footer-content .main-footer-description {
    font-weight: normal;
    font-size: 20px;
    color: #101D36;
    margin-right: 60px;
}

.main-footer .main-footer-content .footer-donate-button {
    border-radius: 5px;
    padding: 15px;
    width: 200px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    background: #101D36;
    color: #FFFFFF;
}

.main-container .search-results-container {
    margin: auto;
    width: 60%;
    text-align: left;
    background: #FFFDFF;
    box-shadow: 0 4px 33px rgba(16, 29, 54, 0.1);
    border-radius: 0 0 10px 10px;
    position: relative;
    top: -10px;
    padding: 45px 55px;
}

.main-container .search-results .search-header {
    font-weight: 600;
    font-size: 18px;
    color: #101D36;
    opacity: 0.8;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    padding: 20px 0 20px 0;
}

.main-container .search-results .search-events {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow: auto;
    margin-bottom: 20px;
}

.main-container .search-results .search-events.expanded {
    background-color: rgb(230, 230, 230);
}

.main-container .search-results-container .search-events .search-events-title {
    font-weight: 600;
    font-size: 22px;
    color: #101D36;
    padding: 0 0 20px 0;
    font-family: Montserrat;
}

.main-container .search-results-container .search-events .search-events-subtitle {
    margin-bottom: 15px;
}

.main-container .search-results .search-events .event-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.main-container .search-results .search-events .event-container:last-child {
    padding-bottom: 0;
}

.main-container .search-results .search-events .event-container .event-title {
    color: #101D36;
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 15px;
    font-family: Montserrat;
    background: none;
    outline: none;
    border: none;
    text-align: left;
}

.event-title svg {
    margin-left: 10px;
    width: 15px;
}

.main-container .search-results .search-events .event-container .event-text {
    font-weight: normal;
    font-size: 16px;
    color: #101D36;
    padding-bottom: 20px;
}

.main-container .search-results .search-events .event-container .event-more a {
    font-weight: bold;
    font-size: 16px;
    color: #556DC9;
}

.main-container .search-results .event-numbers .event-number {
    margin-bottom: 15px;
}

.main-container .search-results .expand-events {
    border: none;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(230, 230, 230);
    margin: auto auto 30px;
    display: block;
}

.main-container .search-results .expand-events svg {
    margin-left: 10px;
}

.main-container .search-results .event-numbers .event-number .number-category {
    font-weight: 600;
    font-size: 16px;
    color: #101D36;
    margin-right: 5px;
}

.main-container .search-results .event-numbers .event-number .number-value {
    color: #ed7d2b;
    font-weight: 600;
    font-size: 16px;
}

.main-container .search-results .results-container {
    padding-bottom: 30px;
}

.main-container .search-results .results-container .google-result-item {
    display: flex;
    flex-direction: column;
}

.main-container .search-results .results-container .google-result-item span {
    color: #A2A1A1;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
}

.main-container .search-results .results-container .google-result-item a {
    color: #3F66FE;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.main-container .search-results .results-container .result-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    align-items: stretch;
}

.main-container .search-results .results-container .result-item img {
    width: 15%;
    object-fit: cover;
    border-radius: 5px;
    aspect-ratio: 2;
}

.main-container .search-results .results-container .result-item-body {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.main-container .search-results .results-container .search-results-header {
    color: #101D36;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: Montserrat;
}

.main-container .search-results .results-container .result-item h3 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
}

.main-container .search-results .results-container .result-item h4 {
    display: block;
    overflow: hidden;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    max-height: 50px;
}

.main-container .search-results .results-container .result-item span {
    color: #C4C4C4;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: Montserrat;
}

.main-container .search-results .results-container .result-item a {
    color: #556DC9;
    font-weight: 700;
    font-size: 16px;
}

.main-container .search-results .results-container .pagination {
    color: #A2A1A1;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.main-container .search-results .results-container .pagination > span:first-child {
    margin-right: 15px;
}

.main-container .search-results .results-container .pagination svg {
    cursor: pointer;
}

.main-container .search-results .results-container .pagination .page-selector {
    margin-left: 5px;
    margin-right: 5px;
}

.main-container .search-results .results-container .google {
    align-items: center;
    cursor: pointer;
}

.main-container .search-results .results-container .google svg {
    margin-left: 20px;
}

.search-type-tabs {
    display: flex;
    flex-direction: row;
}

.main-selectors-container {
    position: relative;
}

.main-selectors-container .reset-search {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: white;
    padding: 10px 0;
}

.search-type-tab {
    background: #FFFDFF;
    color: #C2C2C2;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 40px;
    border: none;
    outline: none;
    margin-right: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.search-type-tab.active {
    background: #556DC9;
    color: #fff;
}

.main-container .search-button {
    background: #556DC9;
    outline: none;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    padding: 25px;
    color: #FFFDFF;
}

.main-container .search-button svg {
    margin-right: 10px;
}

.main-container .main-selectors .keyword-selector {
    flex: 1;
    border: none;
    outline: none;
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.main-container .main-selectors .keyword-selector::placeholder {
    color: #C2C2C2;
}

.main-container .main-selectors .add-filter-btn {
    border: none;
    outline: none;
    background: none;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
}

.main-container .main-selectors .add-filter-btn span {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
    line-height: 20px;
    margin-left: 10px;
    background-color: #556DC9;
    color: #fff;
}

.main-container .main-selectors .add-filter-btn.active {
    background: #e7e6e7;
}

.main-container .search-results-container .keyword-filters {
    display: flex;
    flex-direction: row;
}

.main-container .search-results-container .keyword-filters > * {
    flex: 1;
}

.main-container .search-results-container .keyword-filters > *:not(:first-child) {
    margin-left: 13px;
}

.main-container .search-results-container .active-keyword-filters {
    padding: 20px;
    background-color: #e7e6e7;
    border-radius: 5px;
    margin-bottom: 25px;
}

.main-container .search-results-container .active-keyword-filters-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.main-container .search-results-container .active-keyword-filters-header h3 {
    font-weight: 500;
    font-size: 18px;
    flex: 1;
}

.main-container .search-results-container .active-keyword-filters-header button {
    background: none;
    border: none;
    outline: none;
    color: #556DC9;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    height: fit-content;
}

.main-container .search-results-container .active-keyword-filters .filters-row {
    margin-bottom: 10px;
}

.main-container .search-results-container .chip {
    background: #FFFDFF;
    border-radius: 50px;
    font-size: 13px;
    padding: 2px 8px;
    margin-left: 10px;
}

.main-container .search-results-container .chip > button {
    margin-left: 3px;
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    height: fit-content;
}

.main-container .search-results-container .keyword-filters .filter-placeholder {
    color: #C2C2C2;
}

.main-container .search-results-container .keyword-filters .filter-placeholder svg {
    margin-right: 5px;
}

.main-container .search-results-container .search-results-separator {
    height: 1px;
    background: #C2C2C2;
    margin: 35px -55px;
}

@media (max-width: 768px) {
    .main-container .main-header .main-header-title {
        font-size: 32px;
        line-height: 1.2;
    }

    .main-container .main-header .main-header-description {
        width: auto;
        font-size: 18px;
        line-height: 24px;
    }

    .main-container .main-header {
        padding-left: 16px;
        padding-right: 16px;
    }

    .main-container .search-container {
        max-width: 100%;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .search-type-tab {
        padding: 10px 16px;
        margin-right: 10px;
        font-size: 12px;
    }

    .main-selectors-container .reset-search {
        font-size: 12px;
        position: static;
        float: right;
        width: 58px;
    }

    .main-container .main-selectors .selector-container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .main-container .main-selectors {
        flex-direction: column;
    }

    .main-container .main-selectors .separator {
        width: calc(100% - 16px);
        height: 1px;
        margin: 5px auto 0;
        background-color: #C2C2C2;
    }

    .main-container .search-button {
        font-size: 16px;
        padding: 10px;
        margin-top: 15px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
    }

    .main-container .main-selectors .keyword-selector {
        margin-top: 15px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
    }

    .main-container .main-selectors .add-filter-btn {
        margin-top: 15px;
        font-size: 16px;
    }

    .main-container .main-selectors .add-filter-btn.active {
        background: none;
    }

    .main-container .search-results-container {
        width: calc(100% - 32px);
        margin: 25px 16px auto;
        border-radius: 10px;
        padding: 16px;
    }

    .main-container .search-results-container .keyword-filters {
        flex-direction: column;
    }

    .main-container .search-results-container .keyword-filters > *:not(:first-child) {
        margin: 13px 0 0;
    }

    .main-container .search-results-container .search-results-separator {
        margin: 16px 0;
    }

    .main-container .search-results .results-container .search-results-header {
        font-size: 18px;
    }

    .main-container .search-results-container .search-events .search-events-title {
        font-size: 18px;
    }

    .main-container .search-results .search-events .event-container .event-title {
        font-size: 18px;
    }

    .main-container .search-results .results-container .result-item h3 {
        font-size: 18px;
    }

    .main-container .search-results .results-container .result-item img {
        width: 100%;
    }

    .main-container .search-results .results-container .result-item {
        flex-direction: column;
        margin-bottom: 40px;
    }

    .main-container .search-results .results-container .result-item-body {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
    }

    .main-container .search-results .results-container .google-result-item {
        margin-bottom: 20px;
    }

    .main-container .search-results .results-container .google-result-item span {
        margin-bottom: 5px;
    }

    .main-container .search-results .results-container .google-result-item a {
        font-size: 16px;
        word-break: break-all;
    }

    .main-footer {
        padding-top: 40px;
    }

    .main-footer .main-footer-content {
        padding: 0 30px 40px;
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: center;
        align-items: center;
    }

    .main-footer .main-footer-content .main-footer-description {
        flex: 1;
        margin-right: 32px;
        font-size: 18px;
    }

    .main-footer .main-footer-content .footer-donate-button {
        flex: 0;
        font-size: 18px;
    }
}